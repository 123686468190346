
import axios from '@/plugins/axios'

export default {
  name: 'CategoryNull',
  data: () => ({
    searchNullCarousel: {
      products: [],
      subtitle: '',
      title: '',
      url: ''
    }
  }),
  components: {
    IconSearchNull: () => import('@/assets/icons/icon-search-null.svg'),
    CarouselProducts: () => import('@/components/carrossel/products')
  },
  mounted() {
    this.searchNull()
  },
  methods: {
    async searchNull() {
      const { data } = await axios.ElensApi.get('/carrinho-empty')
      if (data) {
        this.searchNullCarousel.products = data.page_structure.components[0].content[0].products
        this.searchNullCarousel.title = data.page_structure.components[0].content[0].title
        this.searchNullCarousel.url = data.page_structure.components[0].content[0].url
        this.searchNullCarousel.subtitle = data.page_structure.components[0].content[0].subtitle
      }
    }
  }
}
