
import axios from '@/plugins/axios'

export default {
  name: 'CategoryPage',
  props: {
    pageStructure: {
      type: Object,
      default() {}
    },
    pageType: {
      type: String,
      default: 'category'
    }
  },
  components: {
    CardProduct: () => import('@/components/product-card/card-full'),
    FilterMobile: () => import('@/components/category/filterMobile'),
    IconItemFilter: () => import('@/assets/icons/icon-item-filter-mobile.svg'),
    FilterItem: () => import('@/components/category/filterItem'),
    FilterSelected: () => import('@/components/category/filterSelected'),
    Sorting: () => import('@/components/category/sorting'),
    SortingMobile: () => import('@/components/category/sortingMobile')
  },
  data: () => ({
    loadingNextPage: false,
    imageWidth: 450
  }),
  computed: {
    // sorting() {
    //   return this.content.sorting.map((sort) => ({
    //     value: sort.type,
    //     name: sort.title
    //   }))
    // }
  },
  beforeMount() {
    this.updateImageSize()
},
  methods: {
    async CategoryFilter(item) {
      // console.log(item)
      this.pageStructure.products = []
      const { data } = await axios.ElensApi.get(`${item}`)
      data.page_structure.products.forEach((product) => {
        this.pageStructure.products.push(product)
      })
      // this.pageStructure.pagination.next = data.page_structure.pagination.next
      // this.pageStructure.pagination.current = data.page_structure.pagination.current
      // this.pageStructure.pagination.last = data.page_structure.pagination.last
    },
    async callNextPage() {
      if (this.pageStructure.pagination.next !== null && this.pageStructure.pagination.current < this.pageStructure.pagination.last) {
        this.loadingNextPage = true
        const nextPage = this.pageStructure.pagination.current + 1
        const hasQuery = this.$route.fullPath.includes('?')
        let query = ''
        if (localStorage.current_group_id && localStorage.current_group_id === '11') {
          query = hasQuery ? `&page=${nextPage}&bfCatalog=1` : `?page=${nextPage}&bfCatalog=1`
        } else {
          query = hasQuery ? `&page=${nextPage}` : `?page=${nextPage}`
        }
        const { data } = await axios.ElensApi.get(`${this.$route.fullPath}${query}`)
        data.page_structure.products.forEach((product) => {
          this.pageStructure.products.push(product)
        })
        this.pageStructure.pagination.next = data.page_structure.pagination.next
        this.pageStructure.pagination.current = data.page_structure.pagination.current
        this.pageStructure.pagination.last = data.page_structure.pagination.last
        this.loadingNextPage = false
      }
    },
    GAProductClick(product, index) {
      if (window.dataLayer === undefined || window.dataLayer !== undefined) {
        this.$gtm.push({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: { list: product.category[0] },
              products: [{
                name: product.name,
                id: product.id,
                price: product.finalPrice,
                brand: product.lensesBrand,
                category: product.category[0],
                position: index
              }]
            }
          }
        })
      }
    },
    updateImageSize() {
      if (window.innerWidth > 991) {
        this.imageWidth = 300
      } else if (window.innerWidth <= 991) {
        this.imageWidth = 450
      }
    }
  }
}
