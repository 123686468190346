
import axios from '@/plugins/axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CategoryOrCmsPageOrLanding',
  data: () => ({
    productsCategory: '',
    hasTopbar: false,
    bgColor: '#fff',
    txColor: '#000',
    contentPage: {},
    searchNull: false,
    canonical: '',
    hasHome: false
  }),
  computed: {
    ...mapGetters({
      LoginOpen: 'login/modalOpen'
    })
  },
  components: {
    TopBar: () => import('@/components/Header/VHeader-top-bar'),
    HeaderElens: () => import('@/components/Header/Header'),
    NewFooterElens: () => import('@/components/new-Footer/NFooter'),
    Landing: () => import('@/components/format/landing'),
    Static: () => import('@/components/format/static'),
    Category: () => import('@/components/category/category'),
    CategoryDesc: () => import('@/components/category/categoryDesc'),
    Breadcrumb: () => import('@/components/Structure/breadcrumb'),
    BannerFull: () => import('@/components/Structure/bannerFull'),
    Wrapper: () => import('@/components/Structure/Wrapper'),
    CategoryNull: () => import('@/components/category/categoryNull'),
    Rules: () => import('@/components/Structure/Rules'),
    UserPage: () => import('@/components/page-user/UserLoginAndNew')
  },
  head() {
    let seo = ''
    if (this.page_structure.seo !== undefined) {
      seo = this.page_structure.seo
    } else {
      seo = { title: 'Institucional - e-lens', robots: 'INDEX,FOLLOW', description: 'Lentes de Contato com as melhores marcas. Frete Grátis em todo Brasil (ver condições). Garantia de Entrega rápida, facilidade de pagamento e Descontos.' }
    }
    let totalstructurePage = ''
    if (this.page_structure.faqs !== undefined && this.page_type !== 'landing' && this.page_type !== 'product') {
      totalstructurePage = this.page_structure.structuredData.concat(this.page_structure.faqs)
    } else if (this.page_structure.faqs !== undefined && this.page_type === 'product') {
      const structuredFaqs = []
      structuredFaqs.push(this.page_structure.structuredData)
      structuredFaqs.push(this.page_structure.faqs)
      totalstructurePage = structuredFaqs
    } else if (this.page_structure.faqs !== undefined && this.page_type === 'landing') {
      totalstructurePage = this.page_structure.faqs
    } else {
      totalstructurePage = this.page_structure.structuredData
    }
    if (this.page_type === 'category') {
      if (this.$route.path.indexOf('--') !== -1) {
        if (this.$route.path.split('/').length === 2) {
          if (this.$route.path.split('/')[1].indexOf('--') !== -1) {
            if (this.page_structure.type === 'lenses') {
              this.canonical = '/lentes-de-contato'
            }
          } else if (this.$route.path.split('/')[2].indexOf('--') !== -1) {
            this.canonical = '/' + this.$route.path.split('/')[1]
          }
        }
        if (this.$route.path.split('/').length > 2) {
          if (this.$route.path.split('/')[1].indexOf('--') !== -1) {
            if (this.page_structure.type === 'lenses') {
              this.canonical = '/lentes-de-contato'
            }
          } else if (this.$route.path.split('/')[2].indexOf('--') !== -1) {
            this.canonical = '/' + this.$route.path.split('/')[1]
          }
        }
        if (this.$route.path.split('/').length >= 3) {
          if (this.$route.path.split('/')[1].indexOf('--') !== -1) {
            if (this.page_structure.type === 'lenses') {
              this.canonical = '/lentes-de-contato'
            }
          } else if (this.$route.path.split('/')[2].indexOf('--') !== -1) {
            this.canonical = '/' + this.$route.path.split('/')[1]
          } else if (this.$route.path.split('/')[3].indexOf('--') !== -1) {
            this.canonical = '/' + this.$route.path.split('/')[1]
          }
        }
      } else if (this.$route.path.split('/')[1] === 'lentes-de-contato' && this.$route.path.split('/').length === 3) {
        this.canonical = this.$route.path
      } else {
        this.canonical = this.$route.path
      }
    } else {
      this.canonical = this.$route.path
    }
    return {
      title: seo.title || 'e-lens',
      meta: [
        { name: 'robots', content: seo.robots },
        { hid: 'description', name: 'description', content: seo.description || 'Lentes de Contato com as melhores marcas. Frete Grátis em todo Brasil (ver condições). Garantia de Entrega rápida, facilidade de pagamento e Descontos.' },
        { hid: 'og:title', name: 'og:title', content: seo.title },
        { hid: 'og:description', name: 'og:description', content: seo.description },
        { hid: 'og:image', name: 'og:image', content: 'https://static.e-lens.com.br/images/logo-elens-fb.png' },
        { hid: 'image', name: 'image', content: 'https://static.e-lens.com.br/images/logo-elens-fb.png' },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
        { hid: 'twitter:title', name: 'twitter:title', content: seo.title },
        { hid: 'twitter:description', name: 'twitter:description', content: seo.description },
        { hid: 'twitter:image:src', name: 'twitter:image:src', content: 'https://static.e-lens.com.br/images/logo-elens-fb.png'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://www.e-lens.com.br' + this.canonical
        }
      ],
      __dangerouslyDisableSanitizers: ['script'],
      script: [{ innerHTML: JSON.stringify(totalstructurePage), type: 'application/ld+json' }]
    }
  },
  async asyncData({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    try {
      const startTime = new Date().getTime()
      const { data } = await axios.ElensApi.get(`${route.fullPath}`)
      const totalRequestTime = (new Date().getTime() - startTime) / 1000
      data['page_structure']['totalRequestTime'] = totalRequestTime
      data['page_structure']['bannerTopo'] = data.banners
      // data['page_structure']['canonical'] = `https://www.e-lens.com.br${data.page_structure.link}`
      return data
    } catch (err) {
      const { response } = err
      if (response === undefined) {
        console.error(`Error path: ${route.fullPath}`)
      } else if (response.status === 301) {
        const newPath = response.headers.location
        redirect(301, newPath)
      }
      error({ statusCode: 404, message: 'A página que você procura não foi encontrada.' })
    }
  },
  created() {
    this.contentPage = this.page_structure
    if (this.page_header.topbars) {
      for (const item of this.page_header.topbars) {
        if (item.status === true) {
          this.hasTopbar = true
        }
      }
    }
  },
  async mounted() {
    this.toggleModalOpen(false)
    if (this.page_type === 'static') {
      if (window.dataLayer === undefined || window.dataLayer !== undefined) {
        if (localStorage.adobe_analytics_launch_state === '0' || localStorage.adobe_analytics_launch_state === '1') {
          this.$gtm.push({
            event: 'AdobeAnalytics',
            adobeAnalytics: {
              pageType: `Static`,
              pageFirstLevel: `Static`
            }
          })
          if (localStorage.adobe_analytics_launch_state === '0') {
            localStorage.setItem('adobe_analytics_launch_state', 1)
          }
          if (localStorage.adobe_analytics_launch_state === '1') {
            localStorage.setItem('adobe_analytics_launch_state', 2)
          }
        }
      }
    }
    if (this.page_type === 'search') {
      await this.getApi()
      if (window.dataLayer === undefined || window.dataLayer !== undefined) {
        if (localStorage.adobe_analytics_launch_state === '0' || localStorage.adobe_analytics_launch_state === '1') {
          this.$gtm.push({
            event: 'AdobeAnalytics',
            adobeAnalytics: {
              pageType: `Search`,
              pageFirstLevel: `Search`
            }
          })
          if (localStorage.adobe_analytics_launch_state === '0') {
            localStorage.setItem('adobe_analytics_launch_state', 1)
          }
          if (localStorage.adobe_analytics_launch_state === '1') {
            localStorage.setItem('adobe_analytics_launch_state', 2)
          }
        }
      }
    }
    if (this.page_type === 'category') {
      for (const item of this.page_structure.products) {
        if (item.type === 'Lenses') {
          let category = ''
          if (item.category[0] === 'Lentes de Contato') {
            category = 'Contact_lenses'
          } else if (item.category[1] === 'Lentes de Contato') {
            category = 'Contact_lenses'
          }
          this.productsCategory += `,${category};${item.sku};${item.stock.qty};${item.price}`
        }
      }
      if (window.dataLayer === undefined || window.dataLayer !== undefined) {
        if (this.page_structure.data_layer !== undefined) {
          const DL = JSON.parse(this.page_structure.data_layer)
          this.$gtm.push({
            event: DL[0].event || 'setCategory',
            pageType: 'category',
            categoryId: DL[0].categoryId,
            categoryName: this.page_structure.seo.title,
            categoryProducts: DL[0].categoryProducts,
            ecommerce: {
              currencyCode: DL[0].ecommerce[0].currencyCode,
              impressions: DL[0].ecommerce[0].impressions
            }
          })
          let category = ''
          if (DL[0].categoryName === 'Lentes de Contato') {
            category = 'Contact_lenses:Clp'
          }
          if (localStorage.adobe_analytics_launch_state === '0' || localStorage.adobe_analytics_launch_state === '1') {
            this.$gtm.push({
              event: 'AdobeAnalytics',
              adobeAnalytics: {
                pageType: `${category}`,
                pageFirstLevel: `Clp`,
                productsList: this.productsCategory.replace(',', ''),
                events: ''
              }
            })
            if (localStorage.adobe_analytics_launch_state === '0') {
              localStorage.setItem('adobe_analytics_launch_state', 1)
            }
            if (localStorage.adobe_analytics_launch_state === '1') {
              localStorage.setItem('adobe_analytics_launch_state', 2)
            }
          }
        }
      }
    }
    if (this.page_type === 'landing') {
      this.bgColor = this.page_structure.seo.edit_page.bg_color
      this.txColor = this.page_structure.seo.edit_page.tx_color
    }
    if (this.$route.fullPath.includes('central-de-relacionamento')) {
      this.bgColor = '#f7f7f7'
    }
    // setTimeout(() => {
    //   $TB.hooks.fireEvent('pageChange', null, {'sendBeacon': true})
    // }, 5000)
  },
  methods: {
    ...mapActions({
      toggleModalOpen: 'login/AAmodalOpen'
    }),
    async getApi() {
      const { data } = await axios.ElensApi.get(`${this.$route.fullPath}`)
      this.contentPage = data.page_structure
      if (this.contentPage.products.length === 0) {
        this.searchNull = true
      }
    }
  }
}
