

export default {
  name: 'CategoryDesc',
  props: {
    description: {
      type: String,
      default: null
    }
  }
}
